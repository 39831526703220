.signup-form {
    .ant-form {
        padding: 0px 54.62px 0px 55.84px !important;
    }

    .form-label {
        font-size: 16px;
        line-height: 25px;
    }

    .signup-btn {
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-weight: 600px !important;
        border-radius: 6px;
        color: white;
        background-color: #D62314;

        span {
            color: var(--text-color) !important;
        }
    }
}

.signup-form-mobile {
    .ant-form {
        padding: 0px 25px 0px 25px !important;
    }

    .form-label {
        font-size: 16px;
        line-height: 25px;
    }

    .signup-btn {
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-weight: 600px !important;
        border-radius: 6px;
        color: var(--text-color);
        background-color: var(--background-color);

        span {
            color: var(--text-color) !important;
        }
    }
}

body .ant-modal-wrap.login-modal .ant-modal-content {
    // top: 80px;
}

.ant-input:focus,
:where(.css-dev-only-do-not-override-tkuui3).ant-input-focused {
    border-color: var(--background-color) !important;
    box-shadow: none !important;

}

.ant-input:hover {
    border-color: var(--background-color) !important;
    box-shadow: none !important;

}

.ant-input-affix-wrapper:focus {
    border-color: var(--background-color) !important;
    box-shadow: none !important;

}

.ant-input-affix-wrapper-focused {
    border-color: var(--background-color) !important;
    box-shadow: none !important;

}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--background-color) !important;
    box-shadow: none !important;
}